<template>
    <div>
        <div class="bid_wrap license_wrap competition_detail_wrap vote_detail judge_after_wrap"
             v-if="itemData.hasOwnProperty('Item')">
            <div class="bid_main_wrap">
                <div class="bid_content_wrap">
                    <!--                상태변경-->
                    <div class="bid_title_wrap">
                        <h1 class="title">{{ $t('item') }}</h1>
                        <div class="status_change_wrap" v-if="checkUpdate()">
                            <button class="item_status_btn" @click="moveUpdate(`update`)"
                                    :disabled="checkDisabled(entryData)">
                                {{ $t('status_change') }}
                            </button>
                        </div>
                        <!--                    <div class="status_chang_text mt5" v-if="checkDisabled(itemData)">{{ $t('item_status_Msg') }}</div>-->
                        <div class="status_chang_text mt5" v-else-if="entryData.provider === UserInfo.mb_no">
                            {{ $t('item_status_Msg') }}
                        </div>
                    </div>
                    <div class="content_wrap">
                        <!--                    아이템 이미지-->
                        <div class="img_wrap img_content_wrap">
                            <div class="b_img" v-loading="mainImgLoading" @mouseover="imageHover(true)"
                                 @mouseleave="imageHover(false)">
                                <img v-if="spin" class="active" :src="returnSpinThumbnail(mainImg)"
                                     @load="isLoadMainImg"/>
                                <img v-else :src="returnThumbnail(mainImg)" @load="isLoadMainImg"/>
                                <i class="el-icon-refresh reload_icon" :class="{'hover':imgHover}"
                                   @mouseover="imageHover(true)" @mouseleave="imageHover(false)" @click="spinImg()"></i>
                                <!--                                <div class="card_category cc_real play_icon"
                                                                     v-if="isPossiblePlay()" >
                                                                    <img class="icon play" src="@/assets/image/main/icon_play.png" alt="">
                                                                </div>-->
                                <i class="el-icon-caret-right play_icon cursor" v-if="isPossiblePlay()"
                                   @click="openPlayModal()"></i>
                                <!--                              <video-component v-if="returnMediaType()" :media="itemData" :count="1"></video-component>-->
                            </div>
                            <!--                        <div class="card_category cc_real" v-if="itemData.i_type === 'real'">{{ $t('real') }}</div>-->
                            <swiper class="small_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                                    :slidesPerView="'auto'" @click="onClick"
                                    @slideChange="slideChange">

                                <swiper-slide class="s_img">
                                    <img :src="returnSmallThumbnail(orgItemImg)"/>
                                </swiper-slide>
                                <swiper-slide class="s_img"
                                              v-for="(data,index) in thumnailImgs"
                                              :key="`detail${index}`"
                                              v-if="data.org_name != 'no_image.png'"
                                >
                                    <img :src="returnSmallThumbnail(data.org_url)"/>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <div class="inform_wrap inform_content_wrap">
                            <div class="inform_header">
                                <!--                                아이템 타입,공유 좋아요-->
                                <div class="i_header_top">
                                    <div class="left">
                                        <span class="left_span ">{{ returnItemType(entryData) }}</span>
                                    </div>
                                    <div class="right">
                                        <div class="share_btn" @click="openShareModal"></div>
                                        <like-component :likeType="'itemDetail'" :likeData="entryData"></like-component>
                                        <div class="icon_wrap">
                                            <img class="icon eye" src="@/assets/image/main/m_eye.png" alt="">
                                            <div class="icon_count">{{ Number(entryData.im_real_hit) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!--                            유저프로필-->
                                <div class="inform_h_header">
                                    <ul class="thumb_img">
                                        <li>
                                            <el-popover
                                                    :open-delay="500"
                                                    :offset="0"
                                                    @show="getMemberData(entryData,'im_originator')"
                                                    :append-to-body="false"
                                                    :popper-options="{
                                                boundariesElement: 'body',
                                                gpuAcceleration: true,
                                                positionFixed: true,
                                                preventOverflow: true,
                                            }"
                                                    trigger="click"
                                                    popper-class="bid_popover">
                                                <img class="card_profile" slot="reference"
                                                     :src="returnItemProfile(entryData,'originator')">
                                                <!--                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                                                                 @click="moveMember(`creator/${itemData.im_originator}`)">-->
                                                <div class="popover_wrap" v-if="checkMemberData()"
                                                     @click="moveMember(`/creator/${memberData.mb_uuid}`)">
                                                    <div class="popover_top">
                                                        <div class="popover_creator">
                                                            {{ $t('k_creator') }}
                                                        </div>
                                                        <div class="popover_nickname">
                                                            <img class="nickname_img" :src="returnMemberImg()">
                                                            <div class="popover_text_list">
                                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                    <span>Curator</span></div>
                                                                <div class="popover_nickname_list">
                                                                    <div class="nick_text">{{
                                                                            memberData.mb_nick
                                                                        }}
                                                                    </div>
                                                                    <div class="pop_curator"
                                                                         v-if="memberData.g_idx=== 2">
                                                                        <span>Curator</span></div>
                                                                    <div class="pop_curator second_color"
                                                                         v-if="memberData.mb_premium===1">
                                                                        <span>Special</span></div>
                                                                </div>
                                                                <div class="popover_nickname2">
                                                                    <img class="pn_img"
                                                                         src="@/assets/image/main/heart1.png">
                                                                    <div>{{ memberData.likeCnt }}</div>
                                                                    <div>ITEM</div>
                                                                    <div>{{ memberData.itemCnt }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="popover_bottom">
                                                        <div>
                                                            {{ memberData.mb_introduce }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_wrap" v-else>
                                                    <div class="popover_top no_profile">
                                                        <div class="popover_creator no_profile_creator">
                                                            {{ $t('k_creator') }}
                                                        </div>
                                                        <div class="popover_nickname no_profile_nickname">
                                                            <img class="nickname_img no_profile_img"
                                                                 src="@/assets/image/main/no_profile.png">
                                                            <div class="popover_text_list no_profile_text">
                                                                <div class="popover_nickname_list">
                                                                    <div class="nick_text no_profile_text">{{
                                                                            $t('notInfo')
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="popover_bottom no_profile_bottom">
                                                        <div class="pop_pover_div no_profile_div">
                                                            {{ $t('notProvider') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-popover>
                                        </li>

                                        <li>
                                            <el-popover
                                                    :open-delay="500"
                                                    :offset="0"
                                                    @show="getMemberData(entryData,'provider')"
                                                    :append-to-body="false"
                                                    :popper-options="{
                                                boundariesElement: 'body',
                                                gpuAcceleration: true,
                                                positionFixed: true,
                                                preventOverflow: true,
                                            }"
                                                    trigger="click"
                                                    popper-class="bid_popover">
                                                <img class="card_profile" slot="reference"
                                                     :src="returnItemProfile(entryData,'provider')">
                                                <!--                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                                                                 @click="moveMember(`creator/${itemData.provider}`)">-->
                                                <div class="popover_wrap" v-if="checkMemberData()"
                                                     @click="moveMember(`/creator/${memberData.mb_uuid}`)">
                                                    <div class="popover_top">
                                                        <div class="popover_creator">
                                                            {{ $t('owner') }}
                                                        </div>
                                                        <div class="popover_nickname">
                                                            <img class="nickname_img" :src="returnMemberImg()">
                                                            <div class="popover_text_list">
                                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                    <span>Curator</span></div>
                                                                <div class="popover_nickname_list">
                                                                    <div class="nick_text">{{
                                                                            memberData.mb_nick
                                                                        }}
                                                                    </div>

                                                                    <div class="pop_curator second_color"
                                                                         v-if="memberData.mb_premium===1">
                                                                        <span>Special</span></div>
                                                                </div>
                                                                <div class="popover_nickname2">
                                                                    <img class="pn_img"
                                                                         src="@/assets/image/main/heart1.png">
                                                                    <div>{{ memberData.likeCnt }}</div>
                                                                    <div>ITEM</div>
                                                                    <div>{{ memberData.itemCnt }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="popover_bottom">
                                                        <div>
                                                            {{ memberData.mb_introduce }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-popover>
                                        </li>
                                        <li>
                                            <el-popover
                                                    :open-delay="500"
                                                    :offset="0"
                                                    @show="getMemberData(entryData,'ibc_curator')"
                                                    :append-to-body="false"
                                                    :popper-options="{
                                                boundariesElement: 'body',
                                                gpuAcceleration: true,
                                                positionFixed: true,
                                                preventOverflow: true,
                                            }"
                                                    trigger="click"
                                                    popper-class="bid_popover no_profile_popover">
                                                <img class="card_profile" slot="reference"
                                                     :src="returnItemProfile(entryData,'curator')"
                                                     v-if="checkCurator(entryData)">
                                                <!--                                            <div class="popover_wrap"
                                                                                                 @click="moveMember(`creator/${data.ibc_curator}`)">-->
                                                <div class="popover_wrap"
                                                     @click="moveMember(`/creator/${memberData.mb_uuid}`)">
                                                    <div class="popover_top">
                                                        <div class="popover_creator">
                                                            {{ $t('curator') }}
                                                        </div>
                                                        <div class="popover_nickname">
                                                            <img class="nickname_img" :src="returnMemberImg()">
                                                            <div class="popover_text_list">
                                                                <div class="popover_nickname_list">
                                                                    <div class="nick_text">{{
                                                                            memberData.mb_nick
                                                                        }}
                                                                    </div>
                                                                    <div class="pop_curator"
                                                                         v-if="memberData.g_idx=== 2">
                                                                        <span>Curator</span></div>
                                                                    <div class="pop_curator second_color"
                                                                         v-if="memberData.mb_premium===1">
                                                                        <span>Special</span></div>
                                                                </div>
                                                                <div class="popover_nickname2">
                                                                    <img class="pn_img"
                                                                         src="@/assets/image/main/heart1.png">
                                                                    <div>{{ memberData.likeCnt }}</div>
                                                                    <div>ITEM</div>
                                                                    <div>{{ memberData.itemCnt }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="popover_bottom">
                                                        <div>
                                                            {{ memberData.mb_introduce }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-popover>
                                        </li>
                                    </ul>
                                    <!--                                <div class="time_remain" v-if="itemData.i_status === 'auction'">-->
                                    <!--                                    <span>{{ returnItemType(itemData) }}</span>-->
                                    <!--                                </div>-->
                                </div>
                                <!--카테고리-->
                                <div class="header_title">
                                    <h3 class="title_left item">{{ returnCategoryName(entryData) }}</h3>
                                    <div class="card_category cc_real" v-if="entryData.i_type === 'real'">{{
                                            $t('real')
                                        }}
                                    </div>
                                    <h3 class="title_right">{{ entryData.i_name }}</h3>
                                </div>
                                <!--                            에디션-->
                                <div class="edition" v-if="entryData.im_edition === 1">
                                <span v-if="entryData.i_type === 'real'"><span>
                                    {{ $t('edition') }}</span> {{ entryData.im_count }} / {{
                                        entryData.im_total
                                    }}</span>
                                    <span v-else><span>{{ $t('edition') }}</span> {{
                                            entryData.im_count
                                        }} / {{ entryData.im_total }}</span>
                                </div>
                            </div>
                            <div class="inform_content">
                                <div class="hashtag_wrap">
                                    <ul>
                                        <li v-for="(hash,index) in entryData.Hash" :key="`hash${index}`">
                                            <span v-if="hash.ih_type === 1">#{{ returnCategoryDetailName(hash) }}</span>
                                            <span v-else>#{{ hash.ih_hashtag }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <!--                            상세정보-->
                                <ul class="inform_detail_box">
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('type') }}
                                        </div>
                                        <div class="detail_content">
                                            {{ returnType(entryData.i_type) }}
                                        </div>
                                    </li>
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('category') }}
                                        </div>
                                        <div class="detail_content">
                                            {{ returnCategoryName(entryData) }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="checkItemData('im_originator')">
                                        <div class="detail_tit">
                                            {{ $t('k_creator') }}
                                        </div>
                                        <div class="detail_content">
                                            {{ entryData.originator_nick }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="entryData.isEdition === 1">
                                        <div class="detail_tit">
                                            {{ $t('edition') }}
                                        </div>
                                        <div class="detail_content" v-if="entryData.type === 'real'">
                                            {{ entryData.editionCount }} of {{ entryData.editionTotal }}
                                        </div>
                                        <div class="detail_content" v-else>
                                            {{ entryData.editionCount }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="entryData.hasOwnProperty('ItemFile')">
                                        <div class="detail_tit">
                                            {{ $t('certi') }}
                                        </div>
                                        <!--<div class="detail_content color_navy"
                                                v-for="(data,index) in itemData.ItemFile.Guaranty"
                                                :key="`guranty${index}`" v-if="checkImg(data)">
                                                    {{ data.org_name }}
                                        </div>-->
                                        <div class="detail_content color_navy "
                                             :class="{'cursor': entryData.provider === UserInfo.mb_no, 'certi': index > 0}"
                                             v-if="checkImgData('Guaranty')"
                                             v-for="(data,index) in entryData.ItemFile.Guaranty"
                                             @click="awsS3Download('guaranty', data, entryData.i_idx)"
                                             :key="`guranty${index}`">
                                            {{ data.org_name }}
                                        </div>
                                        <div class="detail_content " v-else>
                                            {{ $t('no3') }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="itemData.i_type === 'digital'">
                                        <div class="detail_tit">
                                            {{ $t('f_real') }}
                                        </div>
                                        <div class="detail_content color_navy" v-if="checkImgData('NFT')">
                                            {{ entryData.ItemFile.NFT[0].a_filename }}
                                        </div>
                                    </li>
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('detail_inform') }}
                                        </div>

                                        <ul class="detail_content content_add" v-if="entryData.im_type==='photo'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('v_country2') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('v_si2') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('v_city2') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('size2') }}</h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <!--                                        <li>
                                                                                        <h3>{{ $t('certi2') }}</h3>
                                                                                        <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                        <span v-else>{{ $t('empty') }}</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul class="detail_content content_add" v-else-if="entryData.im_type==='video'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('v_country2') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('v_si2') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('v_city2') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('v_long') }} : </h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <!--                                        <li v-if="checkCerti()">
                                                                                        <h3>{{ $t('certi2') }}</h3>
                                                                                        <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                        <span v-else>{{ $t('empty') }}</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul class="detail_content content_add" v-else-if="entryData.im_type==='audio'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_record') }} : </h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('c_country') }} : </h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('c_si') }} : </h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('c_city') }} : </h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('v_long') }} : </h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <!--                                        <li v-if="checkCerti()">
                                                                                        <h3>{{ $t('certi') }} : </h3>
                                                                                        <span>O</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul class="detail_content content_add" v-else>
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('c_country') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('c_si') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('c_city') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <!--                                        <li v-if="checkCerti()">
                                                                                        <h3>{{ $t('certi2') }}</h3>
                                                                                        <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                        <span v-else>{{ $t('empty') }}</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <!--                            에디션 목록-->
                                <!--                            <item-list-edition-component :item-type="'edition'" :item-key="itemData.i_sku"-->
                                <!--                                                         v-if="itemData.im_edition === 1"></item-list-edition-component>-->
                                <!--                                <item-edition-component :item-key="entryData.i_sku"
                                                                                        v-if="entryData.im_edition === 1 && entryData.i_type==='digital'"></item-edition-component>-->


                                <div class="content_top">
                                    <ul>
                                        <li class="contest">
                                            <h3 class="sub_title">{{ $t('n_vote') }}</h3>
                                            <span>{{
                                                    returnCommas(itemData.ci_vote_cnt)
                                                }}({{
                                                    returnPercentage(itemData.ci_vote_cnt, itemData.totalVote)
                                                }}%)</span>
                                        </li>
                                    </ul>
                                </div>
                                <!--투표-->

                                <div class="btn_wrap" v-if="itemData.UserType === 'normal'">
                                    <button class="gobid" v-if="contestData.c_status === 1" @click="voteItem()">
                                        {{ $t('vote') }}
                                    </button>
                                </div>
                                <!--주최자-->
                                <div class="evaluation_box" v-if="itemData.UserType === 'my'">
                                    <el-collapse v-model="evaulation" class="ev_wrap">
                                        <el-collapse-item v-for="(judge,index) in itemData.Judge"
                                                          :key="`judge-master-${index}`" class="ev_content_box">
                                            <template slot="title">
                                                <div class="amount">
                                                    {{ $t('point') }}<span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                                </div>
                                            </template>
                                            <h3 class="transition-box">{{ $t('r_point') }}</h3>
                                            <div class="transition-box border_box">{{ judge.cij_reason }}</div>
                                            <div class="thumb_wrap">
                                                <img :src="returnProfile(judge)" alt="">
                                                <span>{{ judge.mb_nick }}</span>
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </div>
                                <!--심사위원-->
                                <div class="btn_wrap"
                                     v-if="itemData.UserType === 'judge' && itemData.Judge.length === 0">
                                    <button class="gobid" @click="openJudgeModal()">{{ $t('judge') }}</button>
                                </div>
                                <div class="evaluation_box"
                                     v-if="itemData.UserType === 'judge' && itemData.Judge.length > 0">
                                    <el-collapse v-model="evaulation" class="ev_wrap">
                                        <el-collapse-item v-for="(judge,index) in itemData.Judge"
                                                          :key="`judge-${index}`"
                                                          class="ev_content_box">
                                            <template slot="title">
                                                <div class="amount">
                                                    {{ $t('point') }}<span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                                </div>
                                            </template>
                                            <h3 class="transition-box">{{ $t('r_point') }}</h3>
                                            <div class="transition-box border_box">{{ judge.cij_reason }}</div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </div>
                            </div>
                        </div>
                        <div class="caution_license" v-if="entryData.i_status === 'license' && entryData.il_caution">
                            <h3>{{ $t('caution_license') }}</h3>
                            <p>{{ entryData.il_caution }}</p>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <!--                아이템상세-->
                    <div class="tab_detail_wrap">
                        <div class="item_tab">
                            <ul class="tab_wrap">
                                <li @click="changeTab('item')" :class="{ active : tab === 'item' }" class="item_tab1">
                                    <div class="tab">{{ $t('item') }}</div>
                                </li>
                                <li @click="changeTab('block')" :class="{ active : tab === 'block' }"
                                    class="blockchain_tab1">
                                    <div class="tab">{{ $t('i_block') }}</div>
                                </li>
                                <li @click="changeTab('history')" :class="{ active : tab === 'history' }"
                                    class="nft_tab1">
                                    <div class="tab">{{ $t('nft_history') }}</div>
                                </li>
                            </ul>
                            <div class="info_detail" v-if=" tab === 'item'">

                                <div class="description_wrap mb16">
                                    <h3>{{ $t('i_inform') }}</h3>
                                    <div class="ql-editor" v-html="decodeHtml(replaceBr(entryData.i_memo))">
                                    </div>
                                </div>
                                <!--유저 아이템 리스트 -->
                                <div class="nick_item">
                                    <h3>{{ $t('itemMsg', {nick: entryData.provider_nick}) }}</h3>
                                    <item-list-component :item-type="'userItemList'"
                                                         :memberKey="entryData.provider"
                                                         v-if="entryData.provider"></item-list-component>
                                </div>
                            </div>
                        </div>


                        <div class="block_tab" v-if=" tab === 'block'">

                            <!-- 블록체인 정보 -->
                            <block-chain-component :block-data="entryData.BlockChain"></block-chain-component>
                        </div>

                        <div class="history_tab" v-if=" tab === 'history'">
                            <div class="info_detail">
                                <h3>{{ $t('nft_history') }}</h3>
                                <div class="more_view_btn2" @click="moveHistoryMore('history')">More+</div>
                                <div class="clear"></div>
                                <!-- 아이템 거래내역 -->
                                <history-detail-component v-if="entryData.hasOwnProperty('i_sku')"
                                                          :history-type="'itemDetail'"
                                                          :history-key="entryData.i_sku"></history-detail-component>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <share-modal></share-modal>
        </div>

        <!--        <div class="bid_wrap license_wrap competition_detail_wrap vote_detail judge_after_wrap">
                    <div class="bid_main_wrap">
                        <div class="bid_content_wrap">
                            <div class="bid_title_wrap">
                                <h1 class="title">{{ $t('item') }}</h1>
                            </div>

                            <div class="content_wrap">
                                <div class="img_wrap">
                                    <div class="b_img" v-loading="mainImgLoading" @mouseover="imageHover(true)"
                                         @mouseleave="imageHover(false)">
                                        <img v-if="spin" class="active" :src="returnThumbnail(mainImg,null,730)" @load="isLoadMainImg"/>
                                        <img v-else :src="returnThumbnail(mainImg,730,null)" @load="isLoadMainImg"/>
                                        <i class="el-icon-refresh reload_icon" :class="{'hover':imgHover}"
                                           @mouseover="imageHover(true)" @mouseleave="imageHover(false)" @click="spinImg()"></i>
                                    </div>
                                    <div class="card_category cc_real" v-if="entryData.i_type === 'real'">{{ $t('real') }}</div>
                                    <swiper class="small_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                                            :slidesPerView="'auto'" @click="onClick"
                                            @slideChange="slideChange">
                                        <swiper-slide class="s_img">
                                            <img :src="returnSmallThumbnail(orgItemImg)"/>
                                        </swiper-slide>

                                        <swiper-slide class="s_img"
                                                      v-for="(data,index) in thumnailImgs"
                                                      :key="`detail${index}`"
                                                      v-if="data.org_name != 'no_image.png'">
                                            <img :src="returnSmallThumbnail(data.org_url)"/>
                                        </swiper-slide>
                                    </swiper>
                                </div>
                                <div class="inform_wrap">
                                    <div class="inform_header">
                                        <div class="i_header_top">
                                            <div class="share_btn" @click="openShareModal"></div>
                                            <div class="right">
                                                <like-component v-if="entryData.hasOwnProperty('Item')" :likeType="'itemDetail'"
                                                                :likeData="returnEntryData()"></like-component>
                                                <div class="icon_wrap">
                                                    <img class="icon eye" src="@/assets/image/main/eye1.png" alt="">
                                                    <div class="icon_count">{{ entryData.im_real_hit }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inform_h_header">
                                            <span class="c_nick">{{ $t('k_creator') }} : </span>
                                            <ul class="thumb_img">
                                                <li>
                                                    <img :src="returnItemProfile(entryData,'originator')" alt="">
                                                </li>
                                            </ul>
                                            <span class="c_nick">{{ entryData.originator_nick }}</span>
                                            &lt;!&ndash;                                <div class="time_remain"></div>&ndash;&gt;
                                        </div>

                                        <div class="header_title">
                                            <h3 class="title_left">{{ returnCategoryName(entryData) }}</h3>
                                            <h3 class="title_right">{{ entryData.i_name }}</h3>
                                        </div>

                                        <div class="edition" v-if="entryData.im_edition === 1">
                                        <span v-if="entryData.i_type === 'real'"><span>
                                            {{ $t('edition') }}</span> {{ entryData.im_count }} of {{
                                                entryData.im_total
                                            }}</span>
                                            <span v-else><span>{{ $t('edition') }}</span> {{ entryData.im_count }}</span>
                                        </div>
                                    </div>
                                    <div class="inform_content">
                                        <div class="hashtag_wrap">
                                            <ul>
                                                <li v-for="(hash,index) in entryData.Hash" :key="`hash${index}`">
                                                    <span v-if="hash.ih_type === 1">#{{ hash.ic_name }}</span>
                                                    <span v-else>#{{ hash.ih_hashtag }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="content_top">
                                            <ul>
                                                <li class="contest">
                                                    <h3 class="sub_title">{{ $t('n_vote') }}</h3>
                                                    <span>{{ returnCommas(itemData.ci_vote_cnt) }}({{ returnPercentage(itemData.ci_vote_cnt, itemData.totalVote) }}%)</span>
                                                </li>
                                            </ul>
                                        </div>
                                        &lt;!&ndash;투표&ndash;&gt;

                                        <div class="btn_wrap" v-if="itemData.UserType === 'normal'">
                                            <button class="gobid" v-if="contestData.c_status === 1" @click="voteItem()">
                                                {{ $t('vote') }}
                                            </button>
                                        </div>
                                        &lt;!&ndash;주최자&ndash;&gt;
                                        <div class="evaluation_box" v-if="itemData.UserType === 'my'">
                                            <el-collapse v-model="evaulation" class="ev_wrap">
                                                <el-collapse-item v-for="(judge,index) in itemData.Judge"
                                                                  :key="`judge-master-${index}`" class="ev_content_box">
                                                    <template slot="title">
                                                        <div class="amount">
                                                            {{ $t('point') }}<span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                                        </div>
                                                    </template>
                                                    <h3 class="transition-box">{{ $t('r_point') }}</h3>
                                                    <div class="transition-box border_box">{{ judge.cij_reason }}</div>
                                                    <div class="thumb_wrap">
                                                        <img :src="returnProfile(judge)" alt="">
                                                        <span>{{ judge.mb_nick }}</span>
                                                    </div>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>
                                        &lt;!&ndash;심사위원&ndash;&gt;
                                        <div class="btn_wrap" v-if="itemData.UserType === 'judge' && itemData.Judge.length === 0">
                                            <button class="gobid" @click="openJudgeModal()">{{ $t('judge') }}</button>
                                        </div>
                                        <div class="evaluation_box"
                                             v-if="itemData.UserType === 'judge' && itemData.Judge.length > 0">
                                            <el-collapse v-model="evaulation" class="ev_wrap">
                                                <el-collapse-item v-for="(judge,index) in itemData.Judge" :key="`judge-${index}`"
                                                                  class="ev_content_box">
                                                    <template slot="title">
                                                        <div class="amount">
                                                            {{ $t('point') }}<span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                                        </div>
                                                    </template>
                                                    <h3 class="transition-box">{{ $t('r_point') }}</h3>
                                                    <div class="transition-box border_box">{{ judge.cij_reason }}</div>
                                                </el-collapse-item>
                                            </el-collapse>
                                        </div>


                                    </div>
                                </div>
                                <div class="caution_license" v-if="entryData.i_status === 'license'&& entryData.il_caution">
                                    <h3>{{ $t('caution_license') }}</h3>
                                    <p>{{ entryData.il_caution }}</p>
                                </div>
                            </div>
                            <div class="tab_detail_wrap">
                                <div class="item_tab">
                                    <ul class="tab_wrap">
                                        <li @click="changeTab('item')" :class="{ active : tab === 'item' }" class="item_tab1">
                                            <div class="tab">{{ $t('item') }}</div>
                                        </li>
                                        <li @click="changeTab('block')" :class="{ active : tab === 'block' }"
                                            class="blockchain_tab1">
                                            <div class="tab">{{ $t('i_block') }}</div>
                                        </li>
                                        <li @click="changeTab('history')" :class="{ active : tab === 'history' }" class="nft_tab1">
                                            <div class="tab">{{ $t('nft_history') }}</div>
                                        </li>
                                    </ul>
                                    <div class="info_detail" v-if=" tab === 'item'">
                                        <h3>{{ $t('detail_inform') }}</h3>

                                        <ul v-if="entryData.im_type==='photo'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('v_country2') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('v_si2') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('v_city2') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('size2') }}</h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <li v-if="checkCerti()">
                                                <h3>{{ $t('certi2') }}</h3>
                                                <span>O</span>
                                            </li>
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul v-else-if="entryData.im_type==='video'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('v_country2') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('v_si2') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('v_city2') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('v_long') }} : </h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <li v-if="checkCerti()">
                                                <h3>{{ $t('certi2') }}</h3>
                                                <span>O</span>
                                            </li>
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul v-else-if="entryData.im_type==='audio'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_record') }} : </h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('c_country') }} : </h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('c_si') }} : </h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('c_city') }} : </h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('v_long') }} : </h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <li v-if="checkCerti()">
                                                <h3>{{ $t('certi') }} : </h3>
                                                <span>O</span>
                                            </li>
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul v-else>
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('c_country') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('c_si') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('c_city') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkCerti()">
                                                <h3>{{ $t('certi2') }}</h3>
                                                <span>O</span>
                                            </li>
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <div class="description_wrap mb16">
                                            <h3>{{ $t('i_inform') }}</h3>
                                            <div v-html="decodeHtml(replaceBr(entryData.i_memo))">
                                            </div>
                                        </div>
                                        &lt;!&ndash;유저 아이템 리스트 &ndash;&gt;
                                        <div class="nick_item">
                                            <h3>{{ $t('itemMsg', {nick: entryData.originator_nick}) }}</h3>
                                            <item-list-component :item-type="'userItemList'"
                                                                 :memberKey="entryData.provider"
                                                                 v-if="entryData.provider"></item-list-component>
                                        </div>
                                    </div>
                                </div>


                                <div class="block_tab" v-if=" tab === 'block'">

                                    &lt;!&ndash; 블록체인 정보 &ndash;&gt;
                                    &lt;!&ndash;                        <block-chain-component :block-type="'openMarketBlockChain'"></block-chain-component>&ndash;&gt;
                                    <block-chain-component v-if="itemData.hasOwnProperty('Item')"
                                                           :block-data="itemData.Item[0].BlockChain"></block-chain-component>
                                    <block-chain-component v-else :block-type="'openMarketBlockChain'"></block-chain-component>
                                </div>

                                <div class="history_tab" v-if=" tab === 'history'">
                                    <div class="info_detail">
                                        <h3>{{ $t('nft_history') }}</h3>
                                        <div class="more_view_btn2" @click="moveHistoryMore('history')">More+</div>
                                        <div class="clear"></div>
                                        &lt;!&ndash; 아이템 거래내역 &ndash;&gt;
                                        <history-detail-component v-if="itemData.hasOwnProperty('Item')"
                                                                  :history-type="'itemDetail'"
                                                                  :history-key="itemData.Item[0].i_sku"></history-detail-component>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <share-modal></share-modal>
                </div>-->
        <play-video-modal></play-video-modal>
        <play-audio-modal></play-audio-modal>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ItemListComponent from "@/components/item/ItemListComponent";
import BlockChainComponent from "@/components/blockChain/BlockChainComponent";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import {mapState} from "vuex";
import LikeComponent from "@/components/like/LikeComponent";
import vClickOutside from 'v-click-outside'
import HistoryListComponent from "@/components/history/HistoryListComponent";
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import alertMixins from "@/mixins/alertMixins";
import ShareModal from "@/components/modal/ShareModal";
import ItemDetailOpenMarketLayout from "@/template/item/detail/ItemDetailOpenMarketLayout";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import ItemEditionComponent from "@/components/item/ItemEditionComponent";
import priceMixins from "@/mixins/priceMixins";
import PlayVideoModal from "@/components/modal/PlayVideoModal";
import PlayAudioModal from "@/components/modal/PlayAudioModal";

let timeInterval = null;
export default {
    name: "ItemDetailEntryLayout",
    mixins: [imageOption, dateMixins, AuthMixins, imageResizeMixins, alertMixins, awsS3FileDownload, priceMixins],
    components: {
        ShareModal,
        HistoryDetailComponent,
        HistoryListComponent,
        LikeComponent,
        BlockChainComponent,
        ItemListComponent,
        ItemDetailOpenMarketLayout,
        Swiper,
        SwiperSlide,
        ItemEditionComponent,
        PlayVideoModal,
        PlayAudioModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemKey: this.$route.params.idx,
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
                // loop: true,
            },
            currentTime: '',
            size: {},
            tab: 'item',
            entryData: {},
            evaulation: ['1'],
            contestData: {},
            spin: false,
            mainImgLoading: false,
            imgHover: false,

            memberData: {},
        }
    },

    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('item');
        }
        this.initItemData();
        this.itemCategorys = this.itemCategoryList;
        this.getContest();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveHistoryMore() {
            this.$router.push(`/item/${this.entryData.i_sku}/history`);
        },
        getContest() {
            if (util.isEmpty(this.$route.params.cid)) {
                return false;
            }
            let params = {
                cuid: this.$route.params.cid,
            }
            try {
                this.$api.$competition.getCompetition(params).then(res => res.Data.Result).then(res => {
                    if (util.isEmpty(res.List)) {
                        return false;
                    }
                    this.contestData = res.List[0];
                })
            } catch (e) {
                console.log(e);
            }
        },
        openJudgeModal() {
            this.$modal.show('judge-write-modal', {idx: this.itemData.ci_idx});
        },
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
        },
        checkCurator(data) {
            return !util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator);
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        returnItemType(data) {
            if (util.isEmpty(data.ia_start_date)) {
                return '';
            }
            return data.ia_start_date;

        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },
        checkCerti() {
            return !util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile.Guaranty) && !util.isEmpty(this.entryData.ItemFile.Guaranty[0].a_idx)
        },
        checkItemData(data) {
            return !util.isEmpty(this.entryData[data]);
        },
        onClickOutside() {
        },
        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if (data.ic_idx === 178) {
                return this.$t('prompt');
            }
        },
        checkUpdate() {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no === this.entryData.provider) {
                return true
            }
            return false
        },
        initItemData() {
            this.entryData = !util.isEmpty(this.itemData) && this.itemData.hasOwnProperty('Item') && !util.isEmpty(this.itemData.Item) ? this.itemData.Item[0] : {}
            if (!util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile.Detail)) {
                this.thumnailImgs = this.entryData.ItemFile.Detail;
                this.orgItemImg = this.entryData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
            if (!util.isEmpty(this.entryData) && this.entryData.hasOwnProperty('originator_nick')) {
                EventBus.$emit('setMetaTitle', this.entryData.i_name, this.entryData.originator_nick);
            } else if (!util.isEmpty(this.entryData)) {
                EventBus.$emit('setMetaTitle', this.entryData.i_name);
            }
        },
        returnEntryData() {
            return !util.isEmpty(this.itemData) && this.itemData.hasOwnProperty('Item') && !util.isEmpty(this.itemData.Item) ? this.itemData.Item[0] : {}
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        voteItem() {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                EventBus.$emit('voteItem', this.itemData.ci_idx)
            }
        },
        returnProfile(judge) {
            if (!util.isEmpty(judge) && !util.isEmpty(judge.Profile)) {
                return judge.Profile[0].org_url
            } else {
                return ''
            }
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            if (this.mainImg === elementSrc[0]) {
                return false;
            }

            this.mainImgLoading = true;
            this.changeThumnail(elementSrc[0]);
        },
        openShareModal() {
            this.$modal.show('share-modal', {itemData: this.itemData.Item[0]})
        },
        isLoadMainImg() {
            this.mainImgLoading = false;
        },
        spinImg() {
            this.spin = !this.spin;
        },
        imageHover(type) {
            this.imgHover = type;
        },

        moveUpdate(url) {
            if (this.checkDisabled(this.itemData)) {
                return
            }
            this.$router.push(`${this.$route.path}/${url}`)
        },
        checkDisabled(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.ibc_status) && data.ibc_status === 1 && data.provider === this.UserInfo.mb_no) {
                return true;
            }
            return false;
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        returnType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        moveMember(url) {
            this.$router.push(url)
        },
        checkImgData(data) {
            if (!util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile[data]) && !util.isEmpty(this.entryData.ItemFile[data][0].a_idx)) {
                return true
            }
            return false
        },
        awsS3Download(type, name, key, nft) {
            if (util.isEmpty(this.UserInfo) || this.entryData.provider != this.UserInfo.mb_no) {
                return false;
            }
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },
        returnCategoryDetailName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.ic_name
            }
            return data.ic_name_eng
        },
        isPossiblePlay() {
            return this.orgItemImg === this.mainImg && (this.entryData.im_type === 'video' || this.entryData.im_type === 'audio')
        },
        openPlayModal() {
            if (!this.isPossiblePlay()) {
                return false;
            }
            if (this.entryData.im_type === 'video') {
                this.openVideoModal();
            } else if (this.entryData.im_type === 'audio') {
                this.openAudioModal();
            }
        },
        openVideoModal() {
            if (util.isEmpty(this.entryData.ItemFile) || util.isEmpty(this.entryData.ItemFile.VideoStream)) {
                return false;
            }
            let url = this.entryData.ItemFile.VideoStream[0].org_url;
            let name = this.entryData.ItemFile.VideoStream[0].org_name;
            let thumbnail = this.orgItemImg;
            this.$modal.show('play-video-modal', {'src': url, 'name': name, thumbnail: thumbnail, is360: false});
        },
        openAudioModal() {
            if (util.isEmpty(this.entryData.ItemFile) || util.isEmpty(this.entryData.ItemFile.Original)) {
                return false;
            }
            let url = this.entryData.ItemFile.Original[0].org_url;
            let name = this.entryData.ItemFile.Original[0].org_name;
            let thumbnail = this.orgItemImg;
            this.$modal.show('play-audio-modal', {'src': url, 'name': name, 'thumbnail': thumbnail});
        },

    },
    watch: {
        "$route.fullPath": {
            deep: true,
            handler: function (val, oldVal) {
                if (!this.$route.query.tab) {
                    this.changeTab('item');
                } else if (this.tab != this.$route.query.tab) {
                    this.changeTab(this.$route.query.tab);
                }
            }
        },
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData()
            }
        }
    },
}
</script>

<style scoped>

</style>
